<template>
  <div :id="'customer-card-' + customer.id">
    <base-material-card :color="cardHeaderColor" :background-color="cardBackgroundColor" class="px-2 pt-1 pb-4 customer-card">
      <template #heading>
        <v-container class="pa-0" fluid>
          <v-row no-gutters align="center" justify="center">
            <v-col>{{ name }}</v-col>
            <v-col class="text-right">
              #{{ customer.id }}
            </v-col>
          </v-row>
        </v-container>
      </template>
      <v-card-text>
        <v-expansion-panels v-model="panel" :accordion="true" :multiple="true" :focusable="true" :flat="true" :hover="true" :tile="true" :dense="true">
          <v-expansion-panel>
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.identities.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.identities.table.column.firstName') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.identities.table.column.lastName') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.identities.table.column.company') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="identity in customer.identities" :key="identity.id">
                      <td>{{ identity.firstName }}</td>
                      <td>{{ identity.lastName }}</td>
                      <td>{{ identity.company }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="customer.phones && customer.phones.length > 0">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.phones.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.phones.table.column.type') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.phones.table.column.phone') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.phones.table.column.smsMarketingFrequency') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.phones.table.column.callMarketingFrequency') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="phone in customer.phones" :key="phone.id">
                      <td :class="phone.deletedAt ? 'text-decoration-line-through' : ''">
                        {{ phone.type }}
                      </td>
                      <td :class="phone.deletedAt ? 'text-decoration-line-through' : ''">
                        <a :href="'tel:' + phone.phone" target="_blank">{{ phone.formattedPhone }}</a>
                      </td>
                      <td><v-checkbox :input-value="phone.smsMarketingFrequency === 'all' && phone.deletedAt === undefined" :readonly="true" hide-details color="accent" class="pa-0 ma-0" /></td>
                      <td><v-checkbox :input-value="phone.callMarketingFrequency === 'all' && phone.deletedAt === undefined" :readonly="true" hide-details color="accent" class="pa-0 ma-0" /></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="customer.emails && customer.emails.length > 0">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.emails.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.emails.table.column.email') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.emails.table.column.emailMarketingFrequency') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="email in customer.emails" :key="email.id">
                      <td :class="email.deletedAt ? 'text-decoration-line-through' : ''">
                        <a :href="'mail:' + email.email">{{ email.email }}</a>
                      </td>
                      <td><v-checkbox :input-value="email.emailMarketingFrequency === 'all' && email.deletedAt === undefined" :readonly="true" hide-details color="accent" class="pa-0 ma-0" /></td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="customer.addresses && customer.addresses.length > 0">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.addresses.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.addresses.table.column.address1') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.addresses.table.column.address2') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.addresses.table.column.city') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="address in customer.addresses" :key="address.id" :class="address.deletedAt ? 'text-decoration-line-through' : ''">
                      <td><a :href="'https://maps.google.com/?q=' + String(address.latitude) + ',' + String(address.longitude)" target="_blank">{{ address.address1 }}</a></td>
                      <td>{{ address.address2 }}</td>
                      <td>{{ address.zip }} {{ address.city }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="customer.orders && customer.orders.length > 0">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.orders.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.orders.table.column.name') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.orders.table.column.totalPrice') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.orders.table.column.date') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="order in customer.orders" :key="order.id">
                      <td>{{ order.name }}</td>
                      <td>{{ order.totalPrice }}</td>
                      <td>{{ displayDateISO(order.createdAt) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel v-if="customer.discounts && customer.discounts.length > 0">
            <v-expansion-panel-header :ripple="true" color="quaternary">
              {{ $t('customer.card.section.discounts.title') }}
            </v-expansion-panel-header>
            <v-expansion-panel-content :eager="true">
              <v-simple-table>
                <template #default>
                  <thead>
                    <tr>
                      <th>
                        {{ $t('customer.card.section.discounts.table.column.code') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.discounts.table.column.value') }}
                      </th>
                      <th>
                        {{ $t('customer.card.section.discounts.table.column.date') }}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="discount in customer.discounts" :key="discount.id" :class="discount.deletedAt ? 'text-decoration-line-through' : ''">
                      <td>{{ discount.code }}</td>
                      <td>{{ discount.value }}{{ discount.valueType === 'percentage' ? '%' : '€' }}</td>
                      <td>{{ displayDateISO(discount.createdAt) }}</td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-card-text>

      <template #actions>
        <v-container class="mt-6 pa-0">
          <v-row align="center" justify="center">
            <v-col />
          </v-row>
        </v-container>
      </template>
    </base-material-card>
  </div>
</template>
<script>
  import { displayDateISO } from '@/util/DateUtil';
  import { handleRequestError } from '@/services/common/Http';

  export default {
    name: 'CustomerCard',
    components: {},
    props: {
      customer: {
        type: Object,
        required: true
      }
    },
    data () {
      return {
        panel: [0, 1, 2, 3, 4, 5],
        cardHeaderColor: 'info',
        cardBackgroundColor: 'white'
      };
    },
    computed: {
      name () {
        const identity = Array.isArray(this.customer?.identities) ? this.customer.identities[0] : undefined;
        if (identity === undefined) {
          return '#00' + String(this.customer.id);
        }

        return (identity.firstName ?? '') + ' ' + (identity.lastName ?? '');
      }
    },
    methods: {
      displayDateISO: displayDateISO,
      success (orderId) {
        this.$emit('success', orderId);
      },
      async fail (error, message) {
        this.$emit('fail', await handleRequestError(error, this.$router, this.$i18n, message));
      }
    }
  };
</script>
